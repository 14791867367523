import React from 'react'

import GaleriCard from '../components/GaleriCard'
import './PostSection.css'

class GaleriSection extends React.Component {
  static defaultProps = {
    profil: [],
    title: '',
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () =>
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    const { profil, title, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      visibleProfil = profil.slice(0, limit || profil.length)

    return (
      <div className="PostSection">
        {title && <h2 className="PostSection--Title">{title}</h2>}
        {!!visibleProfil.length && (
          <div className="PostSection--Grid--pejabat">
            {visibleProfil.map((post, index) => (
              <GaleriCard key={post.title + index} {...post} />
            ))}
          </div>
        )}
        {showLoadMore && visibleProfil.length < profil.length && (
          <div className="taCenter">
            <button className="button" onClick={this.increaseLimit}>
              {loadMoreTitle}
            </button>
          </div>
        )}
      </div>
    )
  }
}

export default GaleriSection
