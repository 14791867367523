import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import GaleriSection from '../components/GaleriSection'
import GaleriCategoriesNav from '../components/GaleriCategoriesNav'
import Layout from '../components/Layout'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = album => {
  const now = Date.now()
  return album.filter(post => Date.parse(post.tanggalLahir) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (album, title, contentType) => {
  const isCategory = contentType === 'galeri'
  const byCategory = post =>
    post.categories &&
    post.categories.filter(cat => cat.category === title).length
  return isCategory ? album.filter(byCategory) : album
}

// Export Template for use in CMS preview
export const IndexGaleriTemplate = ({
  title,
  subtitle,
  featuredimage,
  album = [],
  galeri = [],
  enableSearch = true,
  contentType
}) => (
  <Location>
    {({ location }) => {
      let filteredAlbum =
        album && !!album.length
          ? byCategory(byDate(album), title, contentType)
          : []

      let queryObj = location.search.replace('?', '')
      queryObj = qs.parse(queryObj)

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase()
        filteredAlbum = filteredAlbum.filter(post =>
          post.frontmatter.title.toLowerCase().includes(searchTerm)
        )
      }

      return (
        <main className="Blog">
          <PageHeader
            title={title}
            subtitle={subtitle}
          />

          {!!galeri.length && (
            <section className="section thin">
              <div className="container">
                <GaleriCategoriesNav enableSearch categories={galeri} />
              </div>
            </section>
          )}

          {!!album.length && (
            <section className="section">
              <div className="container">
                <GaleriSection album={filteredAlbum} />
              </div>
            </section>
          )}
        </main>
      )
    }}
  </Location>
)

IndexGaleriTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

// Export Default BlogIndex for front-end
const IndexGaleri = ({ data: { page, album, galeri } }) => (
  <Layout>
    <IndexGaleriTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      album={album.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      galeri={galeri.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)



export default IndexGaleri

export const pageQuery = graphql`

  query IndexGaleri($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      fields {
        contentType
      }
      frontmatter {
        title
        templateKey
        subtitle
      }
    }

    album: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "album" } } }
      sort: { order: DESC, fields: [frontmatter___tanggal] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                    fluid(maxWidth: 350, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
            }
            tanggal
            caption
            categories {
              category
            }
          }
        }
      }
    }
    galeri: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "galeri" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }

`
