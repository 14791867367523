import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

import './PostCard.css'

const GaleriCard = ({
  featuredimage,
  title,
  caption,
  tanggal,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  <Link to={slug} className={`PostCard ${className}`}>
      <div className="PostCard--Image relative">
        {featuredimage &&
        <Img
          fluid={featuredimage.childImageSharp.fluid}
          imgStyle={{ objectFit: 'contain' }}
          alt={title} />
        }
      </div>
    <div className="PostCard--Content">
      <div className="PostCard--Category">
      <span className="subtitle is-size-6 is-block">
        {categories && categories.map(cat => cat.category).join(', ')}
      </span>
      </div>
      {caption && <p className="PostCard--Excerpt taCenter">{caption}</p>}
    </div>
  </Link>
)

export default GaleriCard